import React from "react";
import Header from "./components/Header";
import GenreList from "./components/GenreList";
import UserLogin from "./components/UserLogin";
import Homepage from "./components/Homepage";
// theming
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import blueGrey from "@material-ui/core/colors/blueGrey";
import CssBaseline from "@material-ui/core/CssBaseline";

// Material UI Comp Imports
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import TrendingMovies from "./components/TrendingMovies";
import SingleMovie from "./components/SingleMovie";

// Routing
import { Route, Switch } from "react-router-dom";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: blueGrey[500],
      main: blueGrey[700],
      dark: blueGrey[900],
      contrastText: "#fff"
    },
    secondary: {
      main: blueGrey[50]
    }
  },
  spacing: 10
});

const App = props => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <Route path="/" component={Header} />
        <Route exact path="/" component={Homepage} />
        <Container maxWidth="xl">
          <Grid container spacing={2}>
            <Grid item xs={2}>
              {/* <Route path="/" component={GenreList} /> */}
            </Grid>
            <Grid item xs={10}>
              <Grid container spacing={3}>
                {/* <Route exact path="/" component={TrendingMovies} /> */}
                <Route exact path="/login" {...props} component={UserLogin} />
                {/* <Route exact path="/movie/:id" component={SingleMovie} /> */}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </ThemeProvider>
    </>
  );
};

export default App;
