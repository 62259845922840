import React from "react";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  primary: {
    fontSize: "20px",
    letterSpacing: "1.5px",
    display: "block",
    textAlign: "center"
  }
});

const GenreItem = ({ genre }) => {
  const classes = useStyles();
  return (
    <>
      <ListItemText classes={{ primary: classes.primary }}>
        {genre.name}
      </ListItemText>
    </>
  );
};

export default GenreItem;
