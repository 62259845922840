import React, { useEffect } from "react";
import { connect } from "react-redux";
import TrendingMovie from "./TrendingMovie";
import { getTrending } from "../actions";
import SearchResults from "./SearchResults";

//Routing
import { Route } from "react-router-dom";

const TrendingMovies = props => {
  useEffect(() => {
    props.getTrending();
  }, []);

  return (
    <>
      {props.movie.length === 0 && props.isSearching ? (
        <p>Search Returned No Results</p>
      ) : null}

      {props.movie.length > 0
        ? props.movie.map(searchedMovie => {
            return (
              <React.Fragment key={searchedMovie.id}>
                <Route
                  path="/"
                  render={props => (
                    <SearchResults
                      {...props}
                      searchedMovie={searchedMovie}
                      key={searchedMovie.id}
                    />
                  )}
                />
              </React.Fragment>
            );
          })
        : props.trending.map(trend => {
            return (
              <React.Fragment key={trend.id}>
                <Route
                  path="/"
                  render={props => (
                    <TrendingMovie {...props} trend={trend} key={trend.id} />
                  )}
                />
              </React.Fragment>
            );
          })}
    </>
  );
};

const mapStateToProps = state => {
  return {
    trending: state.trendingReducer.trending,
    movie: state.movieSearchReducer.results,
    isSearching: state.movieSearchReducer.isSearching
  };
};

export default connect(
  mapStateToProps,
  { getTrending }
)(TrendingMovies);
