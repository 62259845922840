import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";

import { Link } from "react-router-dom";

const useStyles = makeStyles({
  card: {
    maxWidth: 345,
    borderRadius: "10px",
    marginTop: "50px"
  },
  media: {
    height: 500
  }
});

const TrendingMovie = props => {
  const classes = useStyles();
  const baseURL = "https://image.tmdb.org/t/p/";
  return (
    <>
      <Grid item xs={3}>
        <Card className={classes.card}>
          <CardActionArea>
            <Link to={`movie/${props.trend.id}`}>
              <CardMedia
                className={classes.media}
                image={`${baseURL}w342${props.trend.poster_path}`}
              />
            </Link>
          </CardActionArea>
        </Card>
      </Grid>
    </>
  );
};

export default TrendingMovie;
