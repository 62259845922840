const initialState = {
  results: [],
  isLoading: false,
  isSearching: false,
  error: ""
};

export const movieSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_QUERY_START":
      return {
        ...state,
        isLoading: true,
        isSearching: true
      };
    case "FETCH_QUERY_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isSearching: false,
        results: action.payload
      };
    case "FETCH_QUERY_FAILURE":
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        isSearching: true,
        results: []
      };
    default:
      return state;
  }
};
