import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getMovie, getVideo } from "../actions";
import ReactPlayer from "react-player";
// Material Ui Imports
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles({
  h4: {
    marginTop: "10px"
  },
  p: {
    marginTop: "10px"
  }
});

const SingleMovie = props => {
  const classes = useStyles();
  const movieId = parseInt(props.match.params.id);

  useEffect(() => {
    props.getMovie(movieId);
    props.getVideo(movieId);
  }, [movieId]);

  const youtubeLink = "https://www.youtube.com/watch?v=";

  if (!props.videos.key) {
    return <h1>Loading !!</h1>;
  }

  return (
    <>
      <Grid>
        <Paper>
          <ReactPlayer
            style={{ marginTop: "40px" }}
            url={`${youtubeLink}${props.videos.key}`}
            loop={true}
            muted
          />
          <Typography variant="h4">{props.movie.title}</Typography>
          <Typography variant="p">{props.movie.overview}</Typography>
          <Button onClick={props.history.goBack}>Go Back</Button>
        </Paper>
      </Grid>
    </>
  );
};

const mapStateToProps = state => {
  console.log(state);
  return {
    movie: state.singleMovieReducer.movie,
    videos:
      state.videoReducer.videos.find(video => video.type === "Trailer") || []
  };
};

export default connect(
  mapStateToProps,
  { getMovie, getVideo }
)(SingleMovie);
