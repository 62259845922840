const initialState = {
  videos: [],
  isLoading: false,
  error: ""
};

export const videoReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_VIDEO_START":
      return {
        ...state,
        isLoading: true
      };
    case "FETCH_VIDEO_SUCCESS":
      return {
        ...state,
        isLoading: false,
        videos: action.payload
      };
    case "FETCH_VIDEO_FAILURE":
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
};
