import axios from "axios";

export const getGenres = () => {
  return dispatch => {
    dispatch({ type: "FETCH_GENRE_START" });
    axios
      .get(
        "https://api.themoviedb.org/3/genre/movie/list?api_key=bdf9161edffe87fda5d1b187cad99942"
      )
      .then(res => {
        dispatch({ type: "FETCH_GENRE_SUCCESS", payload: res.data.genres });
      })
      .catch(err => {
        dispatch({ type: "FETCH_GENRE_FAILURE", payload: err.response });
      });
  };
};

export const getTrending = () => {
  return dispatch => {
    dispatch({ type: "FETCH_TRENDING_START" });
    axios
      .get(
        "https://api.themoviedb.org/3/trending/movie/week?api_key=bdf9161edffe87fda5d1b187cad99942"
      )
      .then(res => {
        dispatch({ type: "FETCH_TRENDING_SUCCESS", payload: res.data.results });
      })
      .catch(err => {
        dispatch({ type: "FETCH_TRENDING_FAILURE", payload: err.response });
      });
  };
};

export const getMovie = id => {
  return dispatch => {
    dispatch({ type: "FETCH_MOVIE_START" });
    axios
      .get(
        `https://api.themoviedb.org/3/movie/${id}?api_key=bdf9161edffe87fda5d1b187cad99942`
      )
      .then(res => {
        dispatch({ type: "FETCH_MOVIE_SUCCESS", payload: res.data });
      })
      .catch(err => {
        dispatch({ type: "FETCH_MOVIE_FAILURE", payload: err.response });
      });
  };
};

export const searchMovie = query => {
  return dispatch => {
    dispatch({ type: "FETCH_QUERY_START" });
    axios
      .get(
        `https://api.themoviedb.org/3/search/movie?api_key=bdf9161edffe87fda5d1b187cad99942&query=${query}`
      )
      .then(res => {
        console.log("QUERY RESULTS", res);
        if (res.data.results.length > 0) {
          dispatch({ type: "FETCH_QUERY_SUCCESS", payload: res.data.results });
        } else {
          dispatch({ type: "FETCH_QUERY_FAILURE" });
        }
      })
      .catch(err => {
        dispatch({ type: "FETCH_QUERY_FAILURE", payload: err.response });
      });
  };
};

export const getVideo = id => {
  return dispatch => {
    dispatch({ type: "FETCH_VIDEO_START" });
    axios
      .get(
        `https://api.themoviedb.org/3/movie/${id}/videos?api_key=bdf9161edffe87fda5d1b187cad99942`
      )
      .then(res => {
        console.log("VIDEO RESPONSE FROM API", res.data);
        dispatch({ type: "FETCH_VIDEO_SUCCESS", payload: res.data.results });
      })
      .catch(err => {
        dispatch({ type: "FETCH_VIDEO_FAILURE", payload: err.response });
      });
  };
};

export const getLoginStatus = () => {
  return {
    type: "FETCH_LOGIN_SUCCESS"
  };
};
