import React from "react";
import "../css/Homepage.css";
import Typography from "@material-ui/core/Typography";

const Homepage = () => {
  return (
    <div className="main">
      <h1>Welcome to Search Movie App</h1>
      <h4>Browse through movies, and get feedback from other watchers</h4>
    </div>
  );
};

export default Homepage;
