import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getGenres } from "../actions";
import GenreItem from "./GenreItem";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  h6: {
    marginTop: "50px",
    marginBottom: "30px"
  }
});

const GenreList = props => {
  const classes = useStyles();
  useEffect(() => {
    props.getGenres();
  }, []);

  return (
    <>
      <Typography classes={{ h6: classes.h6 }} variant="h6" align="center">
        All Genres
      </Typography>
      {props.genres.map(genre => {
        return (
          <div key={genre.id}>
            <ListItem button={true}>
              <GenreItem genre={genre} key={genre.id} />
            </ListItem>
            <Divider light={true} />
          </div>
        );
      })}
    </>
  );
};

const mapStateToProps = state => {
  return {
    genres: state.genreReducer.genres,
    loginStatus: state.userLoginReducer.isLoggedIn
  };
};

export default connect(
  mapStateToProps,
  { getGenres }
)(GenreList);
