import { combineReducers } from "redux";
import { genreReducer } from "./genreReducer";
import { trendingReducer } from "./trendingReducer";
import { singleMovieReducer } from "./singleMovieReducer";
import { movieSearchReducer } from "./movieSearchReducer";
import { videoReducer } from "./videoReducer";
import { userLoginReducer } from "./userLoginReducer";

export default combineReducers({
  genreReducer,
  trendingReducer,
  singleMovieReducer,
  movieSearchReducer,
  videoReducer,
  userLoginReducer
});
