const initialState = {
  trending: [],
  isLoading: false,
  error: ""
};

export const trendingReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_TRENDING_START":
      return {
        ...state,
        isLoading: true
      };
    case "FETCH_TRENDING_SUCCESS":
      return {
        ...state,
        isLoading: false,
        trending: action.payload
      };
    case "FETCH_TRENDING_ERROR":
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
};
